import React from 'react'
import { AppLocale } from '@hrk/types'
import { BookmarkPageLoaded, BookmarkPageLoadig, BookmarkSharePage } from '@hrk/huw-module-library'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import { Seo } from '../components/seo/Seo'
import { useTranslations } from '../hooks/useTranslations'
import { useBookmarks } from '../hooks/useBookmarks'
import { useQueryParam } from '../hooks/useQueryParam'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { useUserCodeContext } from '../context/UserCodeContext'
import { useCreateUserCodeMutation } from 'frontendApiGraphqlTypes'
import { navigate } from 'gatsby'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'

const LinkPage: React.FC = () => {
  const currentSlug = '/en/link'
  const currentLocale: AppLocale = 'en'
  const seoTitle = 'Geteilte Merkliste'
  const seoDescription = 'Eine geteilte Merkliste in hoch und weit'
  const [shareCode] = useQueryParam('sharecode', '')
  const { userCode, setUserCode } = useUserCodeContext()
  const {
    bookmarks: bookmarkTranslations,
    bookmarkDialog: bookmarkDialogTranslations,
    search: { filterValues: filterValueTranslations },
  } = useTranslations()
  const { bookmarkLists, createBookmarkList } = useBookmarks()
  const { shareList } = useBookmarks(shareCode)

  const { collectBreadCrumbs } = useNavigationItems()
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: seoTitle, type: 'EditorialPages' })

  const [createUserCode] = useCreateUserCodeMutation({
    fetchPolicy: 'network-only',
    onCompleted: (data) => setUserCode(data.createUserCode.code),
    onError: (error) => {
      console.error(error)
    },
  })

  const sharePageProps = {
    pageLoading: !shareList,
    shareList: shareList,
    bookmarkLists: bookmarkLists,
  } as BookmarkPageLoadig | BookmarkPageLoaded
  return (
    <>
      <Seo title={seoTitle} slug={currentSlug} locale={currentLocale} description={seoDescription}></Seo>
      <LayoutWrapper
        currentTitle={seoTitle}
        currentLocale={currentLocale}
        currentSlug={currentSlug}
        otherLocales={[{ locale: 'de', slug: '/link/' }]}
        currentPath={currentPagePath}
      >
        <BookmarkSharePage
          {...sharePageProps}
          headline={bookmarkTranslations.sharePage.headline}
          introduction={bookmarkTranslations.sharePage.introduction}
          userCode={userCode}
          shareCode={shareCode ?? ''}
          onGenerateNewCode={createUserCode}
          onSaveBookmarkList={createBookmarkList}
          translations={{
            ...bookmarkTranslations.sharePage,
            ...bookmarkDialogTranslations,
            ...filterValueTranslations,
          }}
          navigate={navigate}
        />
      </LayoutWrapper>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/en/link/`} />
}

export default LinkPage
